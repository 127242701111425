import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, useTheme } from "@mui/material";
import { Layout } from "../../components/Layout/Layout";
import "./LeadDetail.scss";
import LeadDetailsTabs from "./LeadDetailsTabs";
import ActionSection from "../../components/LeadDetail/ActionSection/ActionSection";
import AuditSectionTabs from "../lead-details/AuditSectionTab";
import { ActiveLastBreadcrumb } from "../leads/LeadgenerationBreadCrumb";
import OfferModal from "./OfferModal";
import SignedDocumentModal from "./SignedDocumentModal";
import MarkContractStatusCompletedModal from "./MarkContractStatusCompletedModal";
import { useState } from "react";
export const LeadDetail = () => {
    const theme = useTheme();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("orderNumber");
    const id = urlParams.get("id");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSignedDocOpen, setModalSignedDocOpen] = useState(false);
    const [modalMarkContractCompletedOpen, setModalMarkContractCompletedOpen] = useState(false);
    const [actionTabRefresh, setActionTabRefresh] = useState(false);
    const [refreshModalData, setRefreshModalData] = useState(false); //
    const [refreshObject, setRefreshObject] = useState(false);
    const [isLeadSpecial, setIsLeadSpecial] = useState(false);
    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleOpenSignDocumentModal = () => {
        setModalSignedDocOpen(true);
    };
    const handleCloseSignDocumentModal = () => {
        setModalSignedDocOpen(false);
    };
    const handleOpenMarkContractCompletedModal = () => {
        setModalMarkContractCompletedOpen(true);
    };
    const handleCloseMarkContractCompletedModal = () => {
        setModalMarkContractCompletedOpen(false);
    };
    return (_jsxs(Layout, { children: [_jsx(ActiveLastBreadcrumb, { handleOpenModal: handleOpenModal, handleOpenSignDocumentModal: handleOpenSignDocumentModal, handleOpenMarkContractCompletedModal: handleOpenMarkContractCompletedModal, leadNumber: +myParam }), _jsxs(Grid, Object.assign({ container: true, gap: 2 }, { children: [_jsx(Grid, Object.assign({ container: true, item: true, xs: 12, md: 12, lg: 7, sx: {
                            bgcolor: theme.palette.common.white,
                            borderRadius: "24px",
                            padding: "var(--2, 16px) var(--1, 8px)",
                        } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, sx: { justifyContent: "space-between", alignItems: "center" } }, { children: _jsx(LeadDetailsTabs, { refresh: refreshObject, setRefreshModalData: setRefreshModalData, setActionTabRefresh: setActionTabRefresh }) })) })), _jsxs(Grid, Object.assign({ container: true, item: true, xs: 12, md: 12, lg: 4.7, sx: {
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "var(--2, 16px)",
                        } }, { children: [_jsx(ActionSection, { leadId: id, refresh: actionTabRefresh, setIsLeadSpecial: setIsLeadSpecial, setRefreshModalData: setRefreshModalData }), _jsx(Grid, Object.assign({ container: true, item: true, sx: {
                                    justifyContent: "center",
                                    bgcolor: theme.palette.common.white,
                                    borderRadius: "24px",
                                    padding: "var(--2, 16px) var(--1, 8px)",
                                    flexDirection: "column",
                                } }, { children: _jsx(AuditSectionTabs, { refresh: refreshModalData }) }))] }))] })), _jsx(OfferModal, { open: modalOpen, onClose: handleCloseModal, leadId: id, refresh: refreshModalData, setRefreshModalData: setRefreshModalData, setObjectModal: setRefreshObject, setActionTabRefresh: setActionTabRefresh }), _jsx(SignedDocumentModal, { open: modalSignedDocOpen, onClose: handleCloseSignDocumentModal, leadId: id, refresh: refreshModalData, setRefreshModalData: setRefreshModalData, setActionTabRefresh: setActionTabRefresh, setObjectModal: setRefreshObject }), _jsx(MarkContractStatusCompletedModal, { open: modalMarkContractCompletedOpen, onClose: handleCloseMarkContractCompletedModal, leadId: id, refresh: refreshModalData, setRefreshModalData: setRefreshModalData, setActionTabRefresh: setActionTabRefresh, setObjectModal: setRefreshObject })] }));
};
