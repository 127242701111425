var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from "react";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import LeadsObjectApi from "../../../infrastructure/api/pages/lead-object-api";
import CustomModal from "../modal/CustomModal";
const AssignObjectToLeadModal = ({ open, onClose, setRefreshModalData, setActionTabRefresh, setRefresh, setObjectId, objectId, companyRoles, leads, }) => {
    const [leadOptions, setLeadOptions] = useState([]);
    const [companyRoleOptions, setCompanyRoleOptions] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);
    const [selectedCompanyRole, setSelectedCompanyRole] = useState(null);
    const [disable, setDisable] = useState(true);
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const leadSelectRef = useRef(null);
    const companyRoleSelectRef = useRef(null);
    useEffect(() => {
        setLeadOptions(leads.map(({ value, text }) => ({ id: value, label: text })));
    }, [leads]);
    useEffect(() => {
        setCompanyRoleOptions(companyRoles
            .filter(({ id }) => id) // Filter out items with null, undefined, or empty id
            .map(({ id, name }) => ({
            id: id,
            label: name, // Provide default for name if undefined
        })));
    }, [companyRoles]);
    useEffect(() => {
        setDisable(!selectedLead || !selectedCompanyRole);
    }, [selectedLead, selectedCompanyRole]);
    const handleSaveButtonClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedLead || !selectedCompanyRole)
            return;
        setSaving(true);
        try {
            const request = {
                objectId,
                companyRoleId: selectedCompanyRole.id,
                leadId: selectedLead.id,
            };
            const response = yield LeadsObjectApi.assignObjectToLead(request);
            if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                toast.success(translate("Object Assigned Successfully"));
                setRefreshModalData((prev) => !prev);
                setActionTabRefresh((prev) => !prev);
                setRefresh((prev) => !prev);
                setObjectId("");
                onCloseModal();
            }
            else {
                toast.error(translate("Error while assigning Object"));
            }
        }
        catch (error) {
            toast.error(translate("Error while assigning Object"));
        }
        finally {
            setSaving(false);
        }
    });
    const onCloseModal = () => {
        setSelectedLead(null);
        setSelectedCompanyRole(null);
        onClose();
    };
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate("Assign Object To Lead"), saveButtonText: translate("Assign"), disabled: disable }, { children: _jsx(Box, Object.assign({ sx: { marginTop: "1%", padding: "16px 32px", borderRadius: "24px" } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 0 }, { children: [_jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsx(Autocomplete, { disablePortal: true, id: "lead-select", options: leadOptions, onChange: (_, newValue) => setSelectedLead(newValue), sx: { width: 300, marginBottom: "5%" }, ListboxProps: { style: { maxHeight: 150 } }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: translate("Select Lead"), inputRef: leadSelectRef }))) }) })), _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsx(Autocomplete, { disablePortal: true, id: "company-role-select", ListboxProps: {
                                style: { maxHeight: 110, top: 0, overflowY: "auto" },
                            }, options: companyRoleOptions, onChange: (_, newValue) => setSelectedCompanyRole(newValue), sx: { width: 300, marginBottom: "5%" }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: translate("Select Company Role"), inputRef: companyRoleSelectRef }))) }) }))] })) })) })));
};
export default AssignObjectToLeadModal;
