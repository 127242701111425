var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { http_api } from "../../leadg-api";
const getLeadObjectsByLeadId = (leadId) => {
    return http_api.get(`lead-objects/get-by-lead-id/${leadId}`);
};
const getLeadObjectbyObjectId = (id) => {
    return http_api.get(`lead-objects/get-by-id/${id}`);
};
const addLeadObject = (leadObject) => {
    return http_api.post("lead-objects/add", leadObject);
};
const updateLeadObject = (leadObject) => {
    return http_api.put("lead-objects/update", leadObject);
};
const deleteLeadObject = (id) => {
    return http_api.delete(`lead-objects/delete/${id}`);
};
const getSelectionListSystems = () => {
    return http_api.get(`selectionlistsystems/get-dropdown-list`);
};
const getCompanyRoles = () => {
    return http_api.get(`company/get-company-roles`);
};
const isObjectIdExist = (objectId) => {
    return http_api.get(`lead-objects/is-objectid-exist/${objectId}`);
};
const getAllLeadsExceptGivenObjectId = (objectId) => {
    return http_api.get(`lead-objects/leads-except-given-objectid/${objectId}`);
};
const assignObjectToLead = (request) => {
    return http_api.post("lead-objects/assign-object-to-lead", request);
};
const BASE_URL = "https://api.pdok.nl/bzk/locatieserver/search/v3_1/";
const http_api_address = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});
const BAGAPI_BASE_URL = "https://api.bag.kadaster.nl/";
const BAG_API_key = "l70c5087b194494127bcd6ba1f2ba49a93";
const http_api_address_bagapi = axios.create({
    baseURL: BAGAPI_BASE_URL,
    headers: {
        Accept: "application/hal+json",
        "Accept-Crs": "epsg:28992",
        "X-Api-Key": BAG_API_key,
    },
});
const searchLocation = (query) => __awaiter(void 0, void 0, void 0, function* () {
    return http_api_address.get(`suggest?q=${query}&rows=100&fq=type:(adres OR postcode)&fl=id weergavenaam score`);
});
const getLocationById = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return http_api_address.get(`lookup?id=${id}&fl=id woonplaatsnaam weergavenaam postcode centroide_ll huisnummer huisletter huisnummertoevoeging straatnaam adresseerbaarobject_id`);
});
const getPandIdByAddressObjectId = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return http_api_address_bagapi.get(`lvbag/individuelebevragingen/v2/verblijfsobjecten/${id}`);
});
const LeadsObjectApi = {
    getLeadObjectsByid: getLeadObjectsByLeadId,
    addLeadObject,
    updateLeadObject,
    getLeadObjectbyObjectId,
    deleteLeadObject,
    isObjectIdExist,
    searchLocation,
    getLocationById,
    getSelectionListSystems,
    getPandIdByAddressObjectId,
    getCompanyRoles,
    getAllLeadsExceptGivenObjectId,
    assignObjectToLead
};
export default LeadsObjectApi;
