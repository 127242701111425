var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { X } from "@phosphor-icons/react";
import { useEffect, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ContractApi from "../../../infrastructure/api/pages/contract-api";
import CustomModal from "../modal/CustomModal";
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const SignedDocumentModal = ({ open, onClose, leadId, refresh, setRefreshModalData, setActionTabRefresh, setObjectModal }) => {
    const [contractOptions, setContractOptions] = useState([]);
    const [error, setError] = useState(undefined);
    const [disable, setDisable] = useState(true);
    const personSelectRef = useRef(null);
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const [files, setFiles] = useState([]);
    const [selected, setSelected] = useState(null);
    // Compute `disable` state dynamically based on selected contract and uploaded files
    const isSaveDisabled = !selected || files.length === 0;
    const handleFileChange = (event) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };
    const handleDeleteNewDocment = (indexToRemove) => {
        if (indexToRemove != undefined) {
            setFiles((prevFiles) => {
                // Filter out the entry at the specified index
                const newArray = prevFiles.filter((_, index) => index !== indexToRemove);
                return newArray;
            });
        }
    };
    //   const [refresh,setRefresh]=useState<boolean>(false);
    useEffect(() => {
        if (leadId) {
            ContractApi.getActiveContractByLeadIdDropDown(leadId)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    const op = response.data.map((item) => {
                        return {
                            id: item.value,
                            label: item.text,
                            // map other properties if needed
                        };
                    });
                    setContractOptions(op);
                }
            })
                .finally(() => { });
        }
    }, [leadId, refresh]);
    const handleAutocompleteChange = (event, newValue) => {
        setSelected(newValue);
        if (!newValue) {
            setError("Verplicht");
        }
        else {
            setError(undefined);
        }
    };
    const handleSaveButtonClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selected && files.length > 0) {
            setSaving(true);
            // setDisable(true);
            const formData = new FormData();
            files.forEach((file) => formData.append("files", file));
            formData.append("contractId", selected.id);
            yield ContractApi.uploadSignedDocumentData(formData)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("UploadedSuccessfully"));
                    setRefreshModalData((prev) => !prev);
                    setActionTabRefresh((prev) => !prev);
                    setObjectModal((prev) => !prev);
                }
            })
                .finally(() => {
                onCloseModal();
                setSaving(false);
                // setDisable(false);
            });
        }
    });
    const onCloseModal = () => {
        setSelected(null);
        setFiles([]);
        setError(undefined);
        onClose();
    };
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate("Upload Signed Document"), saveButtonText: translate("Upload"), disabled: isSaveDisabled }, { children: _jsx(Box, Object.assign({ sx: {
                marginTop: "1%",
                padding: "16px 32px",
                borderRadius: "24px",
            } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsxs(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: [_jsx(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: contractOptions, onChange: handleAutocompleteChange, sx: { width: 300, marginBottom: "5%" }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: translate("Select Contract"), error: !!error, helperText: error, inputRef: personSelectRef }))) }), _jsxs(Button, Object.assign({ component: "label", variant: "contained", tabIndex: -1, sx: { marginBottom: "5%" }, startIcon: _jsx(CloudUploadIcon, {}) }, { children: [translate("UploadFiles"), _jsx(VisuallyHiddenInput, { type: "file", onChange: handleFileChange })] })), files &&
                            (files === null || files === void 0 ? void 0 : files.map((file, index) => (_jsxs(Grid, Object.assign({ item: true, className: "selected-file" }, { children: [_jsx(Typography, Object.assign({ variant: "body2" }, { children: file.name })), _jsx(X, { size: 16, onClick: () => {
                                            handleDeleteNewDocment(index);
                                        }, cursor: "pointer" })] })))))] })) })) })) })));
};
export default SignedDocumentModal;
