var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useEffect, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ContractApi from "../../../infrastructure/api/pages/contract-api";
import CustomModal from "../modal/CustomModal";
const MarkContractStatusCompletedModal = ({ open, onClose, leadId, refresh, setRefreshModalData, setActionTabRefresh, setObjectModal }) => {
    const [contractOptions, setContractOptions] = useState([]);
    const [error, setError] = useState(undefined);
    const [disable, setDisable] = useState(true);
    const personSelectRef = useRef(null);
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        if (leadId) {
            ContractApi.GetSignedContractThoseInformationNotSubmittedByLeadId(leadId)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    const op = response.data.map((item) => {
                        return {
                            id: item.value,
                            label: item.text,
                            // map other properties if needed
                        };
                    });
                    setContractOptions(op);
                }
            })
                .finally(() => { });
        }
    }, [leadId, refresh]);
    const handleAutocompleteChange = (event, newValue) => {
        setSelected(newValue);
        if (!newValue) {
            setError("Verplicht");
            setDisable(true);
        }
        else {
            setDisable(false);
            setError(undefined);
        }
    };
    const handleSaveButtonClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selected) {
            setSaving(true);
            setDisable(true);
            yield ContractApi.MarkSignedContractStatusCompleted(selected.id)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("Status Updated Successfully"));
                    setRefreshModalData((prev) => !prev);
                    setActionTabRefresh((prev) => !prev);
                    setObjectModal((prev) => !prev);
                }
            })
                .finally(() => {
                onCloseModal();
                setSaving(false);
                setDisable(false);
            });
        }
    });
    const onCloseModal = () => {
        setSelected(null);
        setError(undefined);
        setDisable(true);
        onClose();
    };
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate("Mark Contract Status Completed"), saveButtonText: translate("Mark Completed"), disabled: disable }, { children: _jsx(Box, Object.assign({ sx: {
                marginTop: "1%",
                padding: "16px 32px",
                borderRadius: "24px",
            } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsx(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: contractOptions, onChange: handleAutocompleteChange, sx: { width: 300, marginBottom: "5%" }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: translate("Select Contract"), error: !!error, helperText: error, inputRef: personSelectRef }))) }) })) })) })) })));
};
export default MarkContractStatusCompletedModal;
