import { http_api } from "../../leadg-api";
const getByLeadId = (leadId) => {
    return http_api.get(`contract/get-by-lead-id/${leadId}`);
};
const getById = (id) => {
    return http_api.get(`contract/get-by-id/${id}`);
};
const add = (leadObject) => {
    return http_api.post("contract/add", leadObject);
};
const update = (leadObject) => {
    return http_api.put("contract/update", leadObject);
};
const deleteContract = (id) => {
    return http_api.delete(`contract/delete/${id}`);
};
const getResponsiblePersonByContractId = (contractId) => {
    return http_api.get(`contract/get-responsible-person-by-contractid/${contractId}`);
};
const getNumberOfMonthsForPreferredMonth = (contractId) => {
    return http_api.get(`contract/get-noofmonths-preferredmonth/${contractId}`);
};
const getActiveContractByLeadIdDropDown = (leadId) => {
    return http_api.get(`contract/get-active-contract-by-leadId-dropdown/${leadId}`);
};
const config = {
    headers: {
        "content-type": "multipart/form-data",
    },
};
const uploadSignedDocumentData = (input) => {
    return http_api.post(`contract/upload-signed-document`, input, config);
};
const GetSignedContractThoseInformationNotSubmittedByLeadId = (leadId) => {
    return http_api.get(`contract/get-signed-contract-those-informationunsubmitted-leadId/${leadId}`);
};
const MarkSignedContractStatusCompleted = (contractId) => {
    return http_api.get(`contract/mark-contract-status-completed/${contractId}`);
};
const ContractApi = {
    getByLeadId,
    getById,
    add,
    update,
    deleteContract,
    getResponsiblePersonByContractId,
    getNumberOfMonthsForPreferredMonth,
    getActiveContractByLeadIdDropDown,
    uploadSignedDocumentData,
    GetSignedContractThoseInformationNotSubmittedByLeadId,
    MarkSignedContractStatusCompleted
};
export default ContractApi;
