import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { DownloadSimple, Files, NotePencil, SealCheck } from "@phosphor-icons/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modules } from "../../../application/common/enums/screen-name";
import { checkManage } from "../../../application/common/utils/roles-helper";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
export const ActiveLastBreadcrumb = ({ leadNumber, handleOpenModal, handleOpenSignDocumentModal, handleOpenMarkContractCompletedModal }) => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: { xs: "column", sm: "row", md: "row" }, justifyContent: "space-between", padding: 2 }, { children: [_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ variant: "h1", color: theme.palette.text.primary }, { children: translate("Leadgeneration") })), _jsxs(Breadcrumbs, Object.assign({ "aria-label": "breadcrumb" }, { children: [_jsxs(Link, Object.assign({ underline: "hover", color: "inherit", href: "/leads" }, { children: [_jsx(NotePencil, {}), translate("OrderList")] })), _jsxs(Link, Object.assign({ underline: "hover", color: "inherit" }, { children: [translate("Order"), " #", leadNumber] }))] }))] })), checkManage(RolesPermissions, Modules.Leads) && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: [_jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { marginRight: "5px" }, onClick: handleOpenMarkContractCompletedModal, startIcon: _jsx(SealCheck, {}) }, { children: translate("Mark Contract Completed") })), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { marginRight: "5px" }, onClick: handleOpenSignDocumentModal, startIcon: _jsx(DownloadSimple, {}) }, { children: translate("Upload Signed Document") })), _jsx(Button, Object.assign({ variant: "contained", size: "medium", onClick: handleOpenModal, startIcon: _jsx(Files, {}) }, { children: translate("CreateOffer") }))] })))] })));
};
